@import "tailwindcss/base";
@import "tailwindcss/utilities";
@import "tailwindcss/components";

.btn {
  @apply inline-block;
  @apply px-4;
  @apply py-2;
  @apply rounded;
  @apply text-left;
  @apply w-full;
}

.btn-green {
  @apply bg-green-600;
  @apply text-white;
}

.btn-green:hover {
  @apply bg-green-700;
}

.btn-grey {
  @apply bg-gray-700;
  @apply text-white;
}

.btn-grey:hover {
  @apply bg-gray-800;
}

.text-input {
  @apply border;
  @apply border-gray-700;
  @apply border-solid;
  @apply rounded;
  @apply pt-1;
  @apply pb-1;
  @apply pl-2;
  @apply pr-2;
  @apply mt-2;
  @apply mb-4;
  @apply w-full;
}
